import articlesRenderer from '../../renderers/news_articles'
import articlesRendererOnMobile from '../../renderers/news_articles_banner_mobile'
import articlesRendererV2 from '../../renderers/news_articles_v2'
import newLocalNewsArticlesV2 from '../../renderers/local_news_articles'
import { rapidReady } from '../../../../lib/utils/rapid'
import Carousel from '../../../../lib/client_side_plugins/carousel'

const NEWS_URL = '/nex/modules/news/v1/?m_mode=json&isXHR=true'
const SYNC_EVENT = 'sync:news'
const IE = 'internet explorer'

export default (() => {
  class News {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      if (!this.elems.length) {
        return
      }
      this.utils = window.NEXUtils || {}
      /**
       * Although, at the time of writing, there is only one instance of news on the page
       * this controller supports keeping mulitple instances of the news in sync.
       * Due to this, if any of the instances are set to defer load they will all get updated
       * on the client regardless if they have been loaded server side or not.
       */
      this.defer = false
      for (let i = 0; i < this.elems.length; i++) {
        const {
          defer,
          initialWoeid,
          initialZip,
          readmoreUrl,
          localReadmoreUrl,
          channel,
          renderType,
          slot,
          blueskyAlt,
          legalCompliant,
          initialLat,
          initialLong
        } = this.elems[i].dataset

        if (channel) {
          this.channel = channel
        }
        if (typeof defer !== 'undefined' && (initialWoeid || initialZip)) {
          this.defer = true
          this.woeid = initialWoeid
          this.zip = initialZip
          this.readMoreUrl = readmoreUrl
          this.localReadMoreUrl = localReadmoreUrl
          this.channel = channel
          this.renderType = renderType
          this.slot = slot
          this.blueSkyAlt = blueskyAlt === '1'
          this.isLegalCompliant = legalCompliant === '1'
          this.lat = initialLat
          this.long = initialLong
          break
        }
      }
      this.init()
    }

    init() {
      this.addEventListeners()
      if (this.defer) {
        const requestParams = {
          detail: {
            woeid: this.woeid,
            zip: this.zip,
            lat: this.lat,
            long: this.long
          }
        }
        // If the browser is IE delay the request until the browser is ready
        if (this.utils.getBrowser(window.navigator.userAgent) === IE) {
          this.utils.docIsReady(() => {
            this.fetchNews(requestParams)
          })
        } else {
          this.fetchNews(requestParams)
        }
      }
    }

    addEventListeners() {
      document.addEventListener(SYNC_EVENT, e => {
        this.fetchNews(e)
      })
    }

    updateNews({ articles = [] }) {
      const { readMoreUrl, localReadMoreUrl, slot, renderType, blueSkyAlt, isLegalCompliant } = this
      const renderTypeKey = slot === 'cw_full' ? `${slot}_${renderType}` : renderType
      let markup = ''
      if (renderTypeKey === 'banner_mobile') {
        markup = articlesRendererOnMobile(articles, readMoreUrl)
      } else if (renderTypeKey === 'cw_full_weather_v2') {
        markup = newLocalNewsArticlesV2(articles, readMoreUrl, renderTypeKey, isLegalCompliant)
      } else if (renderTypeKey === 'weather_v2' || renderTypeKey === 'right-rail') {
        markup = articlesRendererV2(
          articles,
          readMoreUrl,
          localReadMoreUrl,
          renderTypeKey,
          slot,
          blueSkyAlt
        )
      } else {
        markup = articlesRenderer(articles, readMoreUrl)
      }
      this.elems.forEach(elem => {
        elem.innerHTML = ''
        elem.appendChild(markup)
        elem._carousel?.onDestroy()
        const carouselClass = 'm-news__carousel'
        const carouselElem = elem.querySelector(`.${carouselClass}`)
        if (carouselElem) {
          elem._carousel = new Carousel(elem, this, { carouselClass })
        }
        const { containerId = '' } = elem.dataset
        this.refreshRapid(containerId)
      })
    }

    refreshRapid(containerId) {
      rapidReady(rapid => {
        if (rapid.isModuleTracked(containerId)) {
          rapid.refreshModule(containerId)
        }
      })
    }

    fetchNews({ detail = {} }) {
      if (!detail.woeid || detail.woeid === '0' || !detail.lat || !detail.long) {
        this.updateNews({ articles: [] })
        return null
      }
      const url = this.channel ? `${NEWS_URL}&channel=${this.channel}` : `${NEWS_URL}`
      detail.renderType = this.renderType
      detail.slot = this.slot
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(detail)
      }
      return fetch(url, options)
        .then(response => response.json())
        .then(({ data = {} }) => {
          const { response = {} } = data
          this.updateNews(response)
          // fire custom event if response.article.length === 0
          if (response.articles && response.articles.length === 0) {
            rapidReady(rapid => {
              const moduleType = this.channel ? this.channel : 'home'
              const errorCode = `lat-${detail.lat}-long-${detail.long}`

              rapid.beaconEvent(`no-data-local-news-${moduleType}`, {
                error_code: errorCode,
                error_type: 'no-data-available',
                error_desc: 'No location news available'
              })
            })
          }
        })
        .catch(err => console.error(err))
    }
  }

  return News
})()
